<template>
  <section id="services">
    <div class=""></div>

    <div class="row narrow section-intro with-bottom-sep animate-this">
      <div class="col-full">
        <h3>Services</h3>
        <h1>What We Do.</h1>

        <p class="lead">
          We provide several technology services
        </p>
      </div>
      <!-- end col-full -->
    </div>
    <!-- end row -->

    <div class="row services-content">
      <div class="services-list block-1-2 block-tab-full group">
        
        <div class="bgrid service-item animate-this">
          <img src="@/assets/images/services/branding.png" width="70"/>
          <div class="service-content">
            <h3 class="h05">Digital Branding</h3>
          </div>
        </div>

        <div class="bgrid service-item animate-this">
          <img src="@/assets/images/services/web design.png" width="70"/>
          <div class="service-content">
            <h3 class="h05">Web Design/Development</h3>
          </div>
        </div>

        <div class="bgrid service-item animate-this">
          <img src="@/assets/images/services/ui_ux.png" width="70"/>
          <div class="service-content">
            <h3 class="h05">UI/UX Design/Development</h3>
          </div>
        </div>

        <div class="bgrid service-item animate-this">
          <img src="@/assets/images/services/digital_marketing.png" width="70"/>
          <div class="service-content">
            <h3 class="h05">Digital Marketing</h3>
          </div>
        </div>

        <div class="bgrid service-item animate-this">
          <img src="@/assets/images/services/software.png" width="70"/>
          <div class="service-content">
            <h3 class="h05">Software Development</h3>
          </div>
        </div>
        
        <div class="bgrid service-item animate-this">
          <img src="@/assets/images/services/mobile.png" width="70"/>
          <div class="service-content">
            <h3 class="h05">Mobile App Development</h3>
          </div>
        </div>

        <div class="bgrid service-item animate-this">
          <img src="@/assets/images/services/cloud.png" width="70"/>
          <div class="service-content">
            <h3 class="h05">Cloud Computing</h3>
          </div>
        </div>

        <div class="bgrid service-item animate-this">
          <img src="@/assets/images/services/network.png" width="70"/>
          <div class="service-content">
            <h3 class="h05">Network Security/Management</h3>
          </div>
        </div>

        <div class="bgrid service-item animate-this">
          <img src="@/assets/images/services/analytics.png" width="70"/>
          <div class="service-content">
            <h3 class="h05">Data Analytics</h3>
          </div>
        </div>

        <div class="bgrid service-item animate-this">
          <img src="@/assets/images/services/integration.png" width="70"/>
          <div class="service-content">
            <h3 class="h05">Testing/Integration</h3>
          </div>
        </div>

        <!-- end bgrid -->
      </div>
      <!-- end services-list -->
    </div>
    <!-- end services-content -->
  </section>
</template>

<script>
export default {};
</script>

<style>
</style>