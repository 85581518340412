<template>
  <section id="contact">
    <div class="overlay"></div>

    <div class="row narrow section-intro with-bottom-sep animate-this">
      <div class="col-twelve">
        <h1>Get In Touch.</h1>

        <p class="lead">
          Don't be shy, get in touch with us.
        </p>
      </div>
    </div>
    <!-- end section-intro -->

    <div class="row contact-content">
      <div class="col-seven tab-full animate-this">
        <h5>Send Us A Message</h5>

        <!-- form -->
        <form name="contactForm" id="contactForm" method="post">
          <div class="form-field">
            <input
              name="contactName"
              type="text"
              id="contactName"
              placeholder="Name"
              value=""
              minlength="2"
              required=""
            />
          </div>

          <div class="row">
            <div class="col-six tab-full">
              <div class="form-field">
                <input
                  name="contactEmail"
                  type="email"
                  id="contactEmail"
                  placeholder="Email"
                  value=""
                  required=""
                />
              </div>
            </div>
            <div class="col-six tab-full">
              <div class="form-field">
                <input
                  name="contactSubject"
                  type="text"
                  id="contactSubject"
                  placeholder="Subject"
                  value=""
                />
              </div>
            </div>
          </div>

          <div class="form-field">
            <textarea
              name="contactMessage"
              id="contactMessage"
              placeholder="message"
              rows="10"
              cols="50"
              required=""
            ></textarea>
          </div>

          <div class="form-field">
            <button class="submitform">Submit</button>

            <div id="submit-loader">
              <div class="text-loader">Sending...</div>
              <div class="s-loader">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
              </div>
            </div>
          </div>
        </form>
        <!-- end form -->

        <!-- contact-warning -->
        <div id="message-warning"></div>

        <!-- contact-success -->
        <div id="message-success">
          <i class="fa fa-check"></i>Your message was sent, thank you!<br />
        </div>
      </div>
      <!-- end col-seven -->

      <div class="col-four tab-full contact-info end animate-this">
        <h5>Contact Information</h5>

        <div class="cinfo">
          <h6>Where to Find Us</h6>
          <p>
            4, Olu Ajilo Street, Ire-Akari Estate,
            Isolo, Lagos-State.
          </p>
        </div>
        <!-- end cinfo -->

        <div class="cinfo">
          <h6>Email Us At</h6>
          <p>
            info@tosoptech.com
          </p>
        </div>
        <!-- end cinfo -->

        <div class="cinfo">
          <h6>Call Us At</h6>
          <p>
            Phone: (+234) 8039271494<br />
            Phone: (+234) 8067183284<br />
          </p>
        </div>
      </div>
      <!-- end cinfo -->
    </div>
    <!-- end row contact-content -->
  </section>
</template>

<script>
export default {};
</script>

<style>
</style>