<template>
<body id="top" :class='menu_class'>
  <header>
    <div class="header-logo">
      <a href="/">
        <img src="@/assets/images/logo.png"/>
      </a>
    </div>

    <button style="background-color: transparent" id="header-menu-trigger" @click="trigger" class="is-clicked">
      <span class="header-menu-text">Menu</span>
      <span class="header-menu-icon"></span>
    </button>

    <nav id="menu-nav-wrap">
      <button @click="trigger" class="close-button" title="close"><span>Close</span></button>

      <h3>TosopTech</h3>

      <ul class="nav-list">
        <li class="current">
          <a class="smoothscroll" href="#home" title="">Home</a>
        </li>
        <li><a class="smoothscroll" href="#about" title="">About</a></li>
        <li><a class="smoothscroll" href="#services" title="">Services</a></li>

        <li><a class="smoothscroll" href="#contact" title="">Contact</a></li>
      </ul>


      <ul class="header-social-list">
        <li>
          <a href="#"><i class="fa fa-facebook-square"></i></a>
        </li>
        <li>
          <a href="#"><i class="fa fa-twitter"></i></a>
        </li>
        <li>
          <a href="#"><i class="fa fa-instagram"></i></a>
        </li>
        <li>
          <a href="#"><i class="fa fa-linkedin"></i></a>
        </li>
      </ul>
    </nav>
    <!-- end #menu-nav-wrap -->
  </header>
</body>
</template>

<script>
export default {
  data() {
    return {
      menu_class: 'pace-done',
    }
  },
  methods: {
    trigger : function(event) {
      if (this.menu_class === 'pace-done') {
        this.menu_class = 'pace-done menu-is-open'
      }else {
        this.menu_class = 'pace-done'
      }
    }
  }
};
</script>

<style>
</style>