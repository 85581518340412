<template>
  <section id="clients">
    <div class="row animate-this">
      <div class="col-twelve">
        <div class="client-lists owl-carousel">
          <div><img src="@/assets/images/clients/mozilla.png" alt="" /></div>
          <div><img src="@/assets/images/clients/bower.png" alt="" /></div>
          <div><img src="@/assets/images/clients/codepen.png" alt="" /></div>
          <div><img src="@/assets/images/clients/envato.png" alt="" /></div>
          <div><img src="@/assets/images/clients/firefox.png" alt="" /></div>
          <div><img src="@/assets/images/clients/grunt.png" alt="" /></div>
          <div><img src="@/assets/images/clients/evernote.png" alt="" /></div>
          <div><img src="@/assets/images/clients/github.png" alt="" /></div>
          <div><img src="@/assets/images/clients/github.png" alt="" /></div>
          <div><img src="@/assets/images/clients/joomla.png" alt="" /></div>
          <div><img src="@/assets/images/clients/github.png" alt="" /></div>
          <div><img src="@/assets/images/clients/jQuery.png" alt="" /></div>
          <div><img src="@/assets/images/clients/github.png" alt="" /></div>
          <div><img src="@/assets/images/clients/wordpress.png" alt="" /></div>
        </div>
      </div>
      <!-- end col-twelve -->
    </div>
    <!-- end row -->
  </section>
</template>

<script>
export default {};
</script>

<style>
</style>