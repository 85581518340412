<template>
  <section id="home">
    <div class="overlay"></div>

    <div class="home-content-table">
      <div class="home-content-tablecell">
        <div class="row">
          <div class="col-twelve">
            <div style="margin-top: 7rem"></div>
                        
            <h1 class="animate-intro">
              We are Reimagining Systems
            </h1>

            <!-- <div class="more animate-intro">
              <p class="colorSmall">Join the Welearnt Experience Programme</p>
              <div class="containner">
                  <div class="email">
                    <input type="email" placeholder="example@mail.com">
                  </div>
                  <button class="btnx">Get early access</button>
              </div>
              <div class="image-space"> 
                <img src="@/assets/images/flags/nigeria.png" height="100"/>
                <img src="@/assets/images/flags/ghana.png" height="100"/>
              </div>
            </div> -->

          </div>
        </div>
      </div>
    </div>

    <ul class="home-social-list">
      <li class="animate-intro">
        <a href="#"><i class="fa fa-facebook-square"></i></a>
      </li>
      <li class="animate-intro">
        <a href="#"><i class="fa fa-twitter"></i></a>
      </li>
      <li class="animate-intro">
        <a href="#"><i class="fa fa-instagram"></i></a>
      </li>
      <li class="animate-intro">
        <a href="#"><i class="fa fa-linkedin"></i></a>
      </li>
    </ul>

    <div class="scrolldown">
      <a href="#about" class="scroll-icon smoothscroll">
        Scroll Down
        <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
      </a>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.containner{
  display: flex;
  justify-content: center;
}

.email input{ 
  padding: 25px 60px;
  height: 6rem !important;
  font-size: 15px;
  color: white;
  border: 0.5px solid rgb(146, 168, 172);
}

.email input:focus{ 
  outline: none;
}

.btnx {
  font-size: 15px;
  height: 6rem;
  background: rgb(53, 53, 53);
  color: white;
  border: none;
  outline: none;
  text-transform: none;
  margin-left: 10px;
}

@media screen and (max-width: 458px) {
  .containner{
    display: inline-block;
  }
  .btnx {
    margin: 0px;
    width: 100%;
  }
}

.image-space img {
  margin-right: 2rem;
}


</style>