<template>
  <section id="portfolio">
    <div class="intro-wrap">
      <div class="row narrow section-intro with-bottom-sep animate-this">
        <div class="col-twelve">
          <h3>Showcase</h3>
          <h1>See Our Featured Projects.</h1>

          <p class="lead">
            Lorem ipsum Dolor adipisicing nostrud et aute Excepteur amet commodo
            ea dolore irure esse Duis nulla sint fugiat cillum ullamco proident
            aliquip quis qui voluptate dolore veniam Ut laborum non est in
            officia.
          </p>
        </div>
      </div>
      <!-- end row section-intro -->
    </div>
    <!-- end intro-wrap -->

    <div class="row portfolio-content">
      <div class="col-twelve">
        <div id="folio-wrap" class="bricks-wrapper">
          

          <div class="brick folio-item">
            <div class="item-wrap animate-this"
              data-src="@/assets/images/portfolio/gallery/g-shutterbug.jpg"
              data-sub-html="#01">
              
              <a href="#">
                <img src="@/assets/images/portfolio/shutterbug.jpg" alt="Skaterboy" />
                <div class="item-text">
                  <span class="folio-types"> Web Development </span>
                  <h3 class="folio-title">Shutterbug</h3>
                </div>
              </a>
              
            </div>


          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
</style>