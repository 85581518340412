<template>
  <footer>     
    <div class="footer-bottom">
      <div class="row">
        <div class="col-twelve">
          <div class="copyright">
            <span>© Copyright Tosoptech {{date}}</span>
            <span> All rights reserved </span> 
            <div style="margin-bottom: 2.5rem"></div>
          </div>
        </div>
      </div>
    </div>
    
    <div id="go-top">
      <a class="smoothscroll" title="Back to Top" href="#top">
        <i class="fa fa-long-arrow-up" aria-hidden="true"></i>
      </a>
    </div>
    
  </footer>
</template>

<script>
export default {
  data () {
    return {
      
      }
  },
  computed : {
    date() {
      var d = new Date();
      return d.getFullYear();
    }
  }
}
</script>

<style>
</style>