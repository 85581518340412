<template>
  <div>
    <Header/>
    <Home/>
    <About/>
    <Services/>
    <!-- <Portfolio/> -->
    <!-- <Testimonials/> -->
    <!-- <Stats/> -->
    <Contact/>
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/header.vue'
import Home from '@/components/home.vue'
import About from '@/components/about.vue'
import Services from '@/components/services.vue'
import Portfolio from '@/components/portfolio.vue'
import Testimonials from '@/components/testimonials.vue'
import Stats from '@/components/stats.vue'
import Contact from '@/components/contact.vue'
import Footer from '@/components/footer.vue'

export default {
  components: {
    Header,
    Home,
    About,
    Services,
    Portfolio,
    Testimonials,
    Stats,
    Contact,
    Footer
  }
}
</script>
