<template>
  <section>

  <div id="about">
    <div class="row about-wrap"> 
      <div class="col-full">
        <div class="about-profile-bg"></div>
        <div class="intro">
          <h3 class="animate-this" style="color: black">ABOUT US</h3>
          <p class="lead animate-this">
            Tosoptech is an <span style="color: white; background: #915B3C">Innovative African Software Company</span> using technology as a primary influence on business strategy, strategic choices, and value-creation models
          </p>
        </div>
      </div>
    </div>
  </div>


<program_card/>

  <div id="about" style="background-color: #ffffff">
    <div class="row about-wrap">
      <div class="col-full">
          <h3 class="animate-this" style="color: black">WE FOCUS ON</h3>
          <p class="color">
            <span>Customer Empowerment</span>
            <br><span>Convergence</span>
            <br><span>Speed and Volatility</span>
            <br><span>Innovation</span>
          </p>
      </div>
    </div>
  </div>

</section>
</template>

<script>
import program_card from './program_card.vue';
export default {
  components: { program_card },};
</script>

<style>
</style>