<template>
  <section>
      <div class="container">
  <div class="card">
    <span></span>
    <div class="content">
        <img src="@/assets/images/advancement.png" width="60"/>
        <h3>Productive Development</h3>
    </div>
  </div>
  <div class="card">
    <span></span>
    <div class="content">
        <img src="@/assets/images/imagination.png" width="60"/>
       <h3>Imagination at Work</h3>
    </div>
  </div>
  <div class="card">
    <span></span>
    <div class="content">
        <img src="@/assets/images/value.png" width="60"/>
       <h3>Driven by value</h3>
    </div>
  </div>
</div>
  </section>
</template>

<script>
export default {

}
</script>

<style scoped>


.container {
    background-color: white;
    padding: 40px 0;
    display:flex; justify-content: center;align-items: center; flex-wrap:wrap;
}

.card {
  position: relative;
  width:300px; height:300px;
  margin: 10px 15px;
  background: rgb(26, 17, 0);
  display:flex; align-items:center; justify-content:center;
  transition: all .3s;
}

.card:hover {transform: translateY(-7px); }
.card::before,
.card::after {
  content: '';
  position: absolute;
  height:100%; width:100%;

}

span {
  position:absolute; top:4px;right:4px;bottom:4px;left:4px;
  z-index: 10;
  border-radius: 25px;
}
span::before {
  content: '';
  position: absolute;
  height:100%; width:50%;
}

.content {
  z-index: 100;
  padding: 20px 35px;
}

h3 {
  color: #eee;
  margin-bottom: 10px;
  font-size: 30px;
  text-transform: capitalize
}

p {
  color: #ccc;
  line-height: 1.5;
  text-align: justify;
  margin-bottom: 15px;
  font-size: 14px;
}

</style>